<div class="container content">
  <app-page-header title='Closed Loop Programs'>
<!--    <button [disabled]="newDisable" class="btn btn-primary btn-sm mt-1 mb-1" (click)="newRewardProgram()">New Reward Program</button>-->
  </app-page-header>

  <div class="row">
    <p-table [value]="closedLoopPrograms"  [columns]="closedLoopProgramsCols" [rows]="25" [paginator]="true" [pageLinks]="8" [rowsPerPageOptions]="[25,50,100]"
             [autoLayout]="true" class="col-12" [loading]="loading"
             [lazy]="true" (onLazyLoad)="loadClosedLoopProgramsLazy($event)" [totalRecords]="closedLoopProgramsTotalRecords" styleClass="p-datatable-gridlines" #closedLoopDT>
      <ng-template pTemplate="header">
        <tr>
          <th *ngFor="let col of closedLoopProgramsCols" [pSortableColumn]="col.sort ? col.field : null">{{col.header}} <p-sortIcon *ngIf="col.sort" [field]="col.field"></p-sortIcon></th>
        </tr>
      </ng-template>
      <ng-template pTemplate="body" let-program>
        <tr>
          <td *ngFor="let col of closedLoopProgramsCols" [ngSwitch]="col.field" >
            <ng-container *ngSwitchCase="'created'">
              <app-value-display [value]="program?.created" type="timestamp"></app-value-display>
            </ng-container>
            <ng-container *ngSwitchCase="'status'">
              <i *ngIf="!program?.removed" style="color:green" class="fa fa-check-circle-o fa-lg" pTooltip="Active"></i>
              <i *ngIf="program?.removed" style="color:red" class="fa fa-times-circle-o fa-lg" pTooltip="Removed"></i>
            </ng-container>
            <ng-container *ngSwitchCase="'action'">
              <a *ngIf="!program?.removed" class="d-none d-lg-inline-block" routerLink="/offers/merchant/{{program.brand.uuid}}" routerLinkActive="active">View</a>
            </ng-container>
            <ng-container *ngSwitchCase="'description'">
              <app-value-display [value]="program.description" type="tags"></app-value-display>
            </ng-container>
            <ng-container *ngSwitchDefault>
              <app-value-display [value]="program[col.field]"></app-value-display>
            </ng-container>
          </td>
        </tr>
      </ng-template>
      <ng-template pTemplate="footer" *ngIf="closedLoopProgramsTotalRecords != null">
        <tr>
          <td [attr.colspan]="closedLoopProgramsCols.length" class="text-center w-100">
            <div class="text-center">{{closedLoopProgramsTotalRecords}} results</div>
          </td>
        </tr>
      </ng-template>
      <ng-template pTemplate="emptymessage">
        <tr>
          <td [attr.colspan]="closedLoopProgramsCols.length" class="text-center w-100">
            No closed loop programs found.
          </td>
        </tr>
      </ng-template>
    </p-table>
  </div>
</div>
