<div class="container content p-2" *ngIf="!obj?.uuid && !loading">
  <div class="row">
    <div class="col-12 text-center">
      <i class="fa fa-warning"></i><br/>
      Unable to load merchant.
    </div>
  </div>
</div>
<div class="container content pt-2" *ngIf="obj?.uuid" [ngClass]="{'border-danger bg-light': 'userIsClosed' == 'true'}">
  <div class="row">
    <div class="col-8 col-md-10">
      <ng-container *ngIf="obj?.name">
        <h3>
          <app-edit-in-place [value]="obj?.name" (onSaved)="updateName($event)"></app-edit-in-place>
          <span *ngIf="obj?.removed"><small><p-chip label="Removed"
                                                    [style]="{fontSize: '12px'}"></p-chip></small></span>
        </h3>

        <app-edit-in-place [value]="obj?.previewText" (onSaved)="updatePreviewText($event)"></app-edit-in-place>
      </ng-container>
      <ng-container *ngIf="!obj?.name">
        <h3>{{ obj?.uuid }} </h3>
      </ng-container>
    </div>
    <div class="col-4 col-md-2 text-right">
      <h4 class="text-muted">{{ obj?.type === 'BRAND' ? "Brand" : "Merchant" }}</h4>
      <button class="btn btn-danger" *ngIf="displayDelete() && !obj.removed" (click)="confirmDelete()">Delete</button>
      <!--      <div class="dropdown pt-1">-->
      <!--        <a class="btn btn-outline-secondary dropdown-toggle" href="#" role="button" id="dropdownMenuLink" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">-->
      <!--          Actions-->
      <!--        </a>-->
      <!--      </div>-->
    </div>
    <div class="col-12 py-0 my-0">
      <hr>
    </div>
  </div>

  <div class="row" *ngIf="loading">
    <div class="col">&nbsp;</div>
    <div class="text-center col">
      <p-progressSpinner></p-progressSpinner>
    </div>
    <div class="col">&nbsp;</div>
  </div>

  <div class="row" *ngIf="obj  && !loading">
    <app-st-card-view title="Info" [loading]="loading" class="col-12 col-md-6 col-lg-6" [subtitle]="obj?.uuid">
      <app-st-card-view-row title="Created" class="col-12">
        <app-value-display [value]="obj.created" type="timestamp"></app-value-display>
      </app-st-card-view-row>
      <app-st-card-view-row title="Updated" class="col-12">
        <app-value-display [value]="obj.updated" type="timestamp"></app-value-display>
      </app-st-card-view-row>
      <app-st-card-view-row title="Organization" class="col-12" *ngIf="obj?.entOrgId">
        <a class="d-none d-lg-inline-block" routerLink="/enterprise/organization/{{obj?.entOrgId}}"
           routerLinkActive="active">{{ obj?.entOrgId }}</a>
      </app-st-card-view-row>
      <app-st-card-view-row title="Brand" class="col-12" *ngIf="obj?.entBrandId">
        <a class="d-none d-lg-inline-block" routerLink="/enterprise/organization/{{obj?.entBrandId}}"
           routerLinkActive="active">{{ obj?.entBrandId }}</a>
      </app-st-card-view-row>
      <app-st-card-view-row title="Channel" class="col-12 py-1" *ngIf="obj?.type === 'MID'">
        <app-edit-dropdown [options]="channelOptions" [value]="obj?.channel" (onSaved)="updateChannel($event)">
          <ng-container *ngIf="obj?.channel">
            {{ obj.channel }}
          </ng-container>
          <ng-container *ngIf="!obj?.channel">
            <span class="text-muted">Set</span>
          </ng-container>
        </app-edit-dropdown>
      </app-st-card-view-row>
      <app-st-card-view-row title="Endpoint" class="col-12 py-1"
                            *ngIf="obj?.type === 'MID' && (obj?.channel === 'ONLINE' || obj?.channel === 'OL_STO')">
        <app-edit-in-place [value]="obj?.endpoint" (onSaved)="updateEndpoint($event)">
          <ng-container class="row-outside" *ngIf="obj?.endpoint">
            <a href="{{obj?.endpoint}}" target="_blank">
              <i class="fa fa-lg fa-external-link ml-1"></i>
            </a>
          </ng-container>
        </app-edit-in-place>
      </app-st-card-view-row>
      <app-st-card-view-row title="Image" class="col-12 py-1">
        <app-edit-in-place [value]="obj?.image" type="image" (onSaved)="saveImage($event)"></app-edit-in-place>
      </app-st-card-view-row>
      <app-st-card-view-row title="Avatar" class="col-12 py-1">
        <app-edit-in-place [value]="obj?.avatar" type="image" (onSaved)="saveAvatar($event)"></app-edit-in-place>
      </app-st-card-view-row>
      <app-st-card-view-row title="Verified" class="col-12">
        <div class="d-flex flex-column align-items-end">
          <p-inputSwitch [disabled]="allowVerifyToggle()" [(ngModel)]="obj.verified"
                         (onChange)="handleVerifiedChange($event)" ariaLabelledBy="verifyDisabled"></p-inputSwitch>
          <small class="font-italic text-danger" id="verifyDisabled" *ngIf="allowVerifyToggle()">missing identifier,
            cannot verify</small>
        </div>
      </app-st-card-view-row>
      <app-st-card-view-row title="Gift Option" class="col-12">
        <p-inputSwitch [(ngModel)]="obj.giftOption" (onChange)="handleGiftOptionChange($event)"></p-inputSwitch>
      </app-st-card-view-row>
    </app-st-card-view>

    <app-st-card-view title="Reward Program" [loading]="loading" class="col-12 col-md-6 col-lg-6"
                      *ngIf="obj?.rewardProgram">
      <ng-container class="st-card-subtitle" *ngIf="obj?.rewardProgram?.uuid">
        <a routerLink="/offers/rewardprogram/{{obj['rewardProgram']['uuid']}}" routerLinkActive="active">View</a>
      </ng-container>
      <ng-container *ngIf="obj?.rewardProgram">
        <app-st-card-view-row title="Title" class="col-12">
          {{ obj?.rewardProgram?.title }}
        </app-st-card-view-row>
        <app-st-card-view-row title="Created" class="col-12">
          {{ obj?.rewardProgram?.created | date:'medium' }}
        </app-st-card-view-row>
      </ng-container>
      <ng-container *ngIf="!(obj?.rewardProgram) && obj?.type === 'BRAND'">
        <span class="text-center m-auto">
          <button [disabled]="newRewardProgramDisable" type="button" pButton (click)="createRewardsProgram()"
                  label="Create Rewards Program"></button>
        </span>
      </ng-container>
    </app-st-card-view>

    <app-st-card-view title="Closed Loop Program" [loading]="loading" class="col-12 col-md-6 col-lg-6"
                      *ngIf="obj?.type === 'BRAND'">
      <ng-container class="st-card-subtitle" *ngIf="obj?.closedLoopProgram">
        <button [disabled]="newRewardProgramDisable" type="button" (click)="handleEditClosedLoopProgram()"
                class="btn btn-primary btn-sm mr-1">Edit
        </button>
        <button [disabled]="newRewardProgramDisable" type="button" (click)="handleDeleteClosedLoopProgram()"
                class="btn btn-danger btn-sm">Delete
        </button>
      </ng-container>
      <ng-container *ngIf="obj?.closedLoopProgram">
        <app-st-card-view-row title="Title" class="col-12">
          <app-value-display [value]="obj?.closedLoopProgram?.title"></app-value-display>
        </app-st-card-view-row>
        <app-st-card-view-row title="Created" class="col-12">
          <app-value-display [value]="obj?.closedLoopProgram?.created" type="timestamp"></app-value-display>
        </app-st-card-view-row>
        <app-st-card-view-row title="Description" class="col-12">
          <app-value-display [value]="obj?.closedLoopProgram?.description" type="tags"></app-value-display>
        </app-st-card-view-row>
        <app-st-card-view-row title="Image" class="col-12">
          <app-value-display [value]="obj?.closedLoopProgram?.image" type="image"></app-value-display>
        </app-st-card-view-row>
        <app-st-card-view-row title="Preview Text" class="col-12">
          <app-value-display [value]="obj?.closedLoopProgram?.previewText"></app-value-display>
        </app-st-card-view-row>
        <app-st-card-view-row title="Mids" class="col-12 custom">
          <div class="d-flex flex-column">
            <ng-container *ngFor="let mid of obj?.closedLoopProgram?.mids">
              <div>
                - {{ mid.mid.alias ? mid.mid.alias : mid.mid.name }}
                <a routerLink="/offers/merchant/{{mid.mid.uuid}}" routerLinkActive="active">
                  <i class="fa fa-lg fa-external-link ml-1"></i>
                </a>
              </div>
            </ng-container>
          </div>
        </app-st-card-view-row>
      </ng-container>
      <ng-container *ngIf="!(obj?.closedLoopProgram)">
        <span class="text-center m-auto">
          <button [disabled]="newRewardProgramDisable" type="button" class="btn btn-primary btn-sm"
                  (click)="newClosedLoopProgram()">Create Closed Loop Program</button>
        </span>
      </ng-container>
    </app-st-card-view>

    <app-st-card-view title="Merchant Identifier (MID)" [loading]="loading" class="col-12 col-md-6 col-lg-6"
                      *ngIf="obj?.type === 'MID'">
      <app-st-card-view-row title="Identifier" class="col-12">
        <app-edit-in-place [value]="obj?.merchantIdentifier" (onSaved)="updateIdentifier($event)"></app-edit-in-place>
      </app-st-card-view-row>
      <app-st-card-view-row title="Alias" class="col-12">
        <app-edit-in-place [value]="obj?.alias" (onSaved)="updateAlias($event)">
          <ng-container *ngIf="obj?.alias">
            {{ obj?.alias }}
          </ng-container>
          <ng-container *ngIf="!obj?.alias">
            <span class="text-muted">Set</span>
          </ng-container>
        </app-edit-in-place>
      </app-st-card-view-row>
    </app-st-card-view>

    <app-st-card-view title="Location(s)" class="col-sm-6 col-md-6 col-lg-6"
                      [loading]="loading" *ngIf="obj?.type === 'MID'"
                      [showEmpty]="simplifyLocationsList.length < 1"
                      emptyMessage="{{!obj?.rewardProgram ? 'No Linked Reward Program' : 'No Locations Found'}}">
      <div class="st-card-subtitle text-muted">
        <p-button label="Add" [disabled]="!obj?.rewardProgram" icon="pi pi-plus" class="p-fluid"
                  styleClass="p-button-sm" (onClick)="showAddLocationFormDialog()"></p-button>
      </div>
      <app-paged-card class="col-12" [rowTemplate]="childTemplate" [items]="simplifyLocationsList"
                      [pageSize]="3"></app-paged-card>
      <ng-template #childTemplate let-location="item">
        <div class="col-12 p-0">
          <app-st-card-view-row title="Alias">
            <app-value-display [value]="location.alias"></app-value-display>
          </app-st-card-view-row>
          <app-st-card-view-row title="Endpoint" *ngIf="location.channel === 'ONLINE'">
            <app-value-display [value]="location.endpoint"></app-value-display>
          </app-st-card-view-row>
          <app-st-card-view-row title="Channel">
            <app-value-display [value]="location.channel"></app-value-display>
          </app-st-card-view-row>
          <app-st-card-view-row title="PlaceName" *ngIf="location.channel === 'IN_STORE'">
            <app-value-display [value]="location.placeName"></app-value-display>
          </app-st-card-view-row>
          <app-st-card-view-row title="Address" *ngIf="location.channel === 'IN_STORE'">
            <app-value-display [value]="location.address"></app-value-display>
          </app-st-card-view-row>
          <app-st-card-view-row title="TerminalId(s)" [align]="'align-items-baseline'">
            <div class="d-flex flex-column align-items-end">
              <ng-container *ngFor="let terminalObj of location.terminals">
                <div class="d-flex align-items-center" style="gap: 5px;">
                  {{ terminalObj.terminal }}
                  <p-button label="" class="p-fluid" styleClass="p-button-sm p-button-danger p-button-text p-0"
                            icon="pi pi-times"
                            (onClick)="handleRemoveTerminalId(terminalObj, location)"
                            pTooltip="Remove Terminal"></p-button>
                </div>
              </ng-container>
            </div>
          </app-st-card-view-row>
          <div class="d-flex justify-content-between">
            <div>
              <p-button label="Remove Location" class="p-fluid" styleClass="p-button-sm p-button-danger p-button-text"
                        icon="pi pi-times"
                        (onClick)="handleRemoveLocation(location)"></p-button>
            </div>
            <div class="d-flex" style="gap: 5px;">
              <p-button label="Edit Location" class="p-fluid" styleClass="p-button-sm p-button-text" icon="pi pi-pencil"
                        (onClick)="displayEditLocationForm(location)"></p-button>
              <p-button label="Add TerminalId" class="p-fluid" styleClass="p-button-sm p-button-text" icon="pi pi-plus"
                        (onClick)="displayEditTerminalForm(location.brandLocationId)"></p-button>
            </div>
          </div>
        </div>
      </ng-template>
    </app-st-card-view>

    <app-st-card-view title="Card Pool Transactions" [loading]="loading" class="col-12" *ngIf="obj?.type === 'BRAND'">
      <app-hapi-merchant-test-transactions-table [brandId]="obj?.uuid"
                                                 class="col-12"></app-hapi-merchant-test-transactions-table>
    </app-st-card-view>
  </div>
</div>

<p-dialog modal="modal" header="Add Location" [draggable]="false" [resizable]="false" [(visible)]="showAddLocationForm"
          [dismissableMask]="true"
          [style]="{width: '900px'}" (onHide)="handleLocationModalClose();">
  <form [formGroup]="addLocationForm" class="p-1" (keydown.enter)="$event.preventDefault()" novalidate>
    <ng-container formArrayName="locations">
      <ng-container *ngFor="let locationFormGroup of getAddLocationFormLocations.controls; let i = index">
        <div class="col-12" [formGroup]="locationFormGroup">
          <div class="d-flex flex-column align-items-center">
            <div class="d-flex w-100">
              <div class="form-group col-4 d-flex flex-column">
                <label class="text-uppercase" for="{{'locationChannel' + i}}">Channel *</label>
                <p-dropdown appendTo="body" class="pull-right p-fluid" styleClass="form-control p-0"
                            [options]="locationChannelOptions"
                            placeholder="Select Channel" formControlName="channel" id="{{'locationChannel' + i}}"
                            [disabled]="disableChannelDropdown"></p-dropdown>
              </div>
              <div class="form-group col-8">
                <label class="text-uppercase" for="{{'alias' + i}}">Alias</label>
                <input autocomplete="off" class="form-control" id="{{'alias' + i}}" type="text" formControlName="alias">
              </div>
            </div>
            <div class="form-group col-12" *ngIf="locationFormGroup.get('channel').value === 'ONLINE'">
              <label class="text-uppercase" for="{{'endpoint' + i}}">Endpoint *</label>
              <input autocomplete="off" class="form-control" id="{{'endpoint' + i}}" type="text"
                     formControlName="endpoint">
            </div>
            <ng-container *ngIf="locationFormGroup.get('channel').value === 'IN_STORE'">
              <div *ngIf="useAddressOverLatLng" class="d-flex flex-column form-group col-12">
                <label class="text-uppercase" for="{{'address' + i}}">
                  Address <span *ngIf="locationFormGroup.get('channel').value === 'IN_STORE'">*</span>
                </label>
                <input ngx-google-places-autocomplete class="form-control"
                       [options]="googlePlaceApiOptions" #placesRef="ngx-places" formControlName="address"
                       (onAddressChange)="handleAddressChange($event, i)"/>
              </div>
              <div *ngIf="!useAddressOverLatLng" class="d-flex w-100">
                <div class="form-group col-6">
                  <label class="text-uppercase" for="{{'latitude' + i}}">
                    Latitude <span *ngIf="locationFormGroup.get('channel').value === 'IN_STORE'">*</span>
                  </label>
                  <input autocomplete="off" class="form-control" id="{{'latitude' + i}}" type="number"
                         formControlName="latitude">
                </div>
                <div class="form-group col-6">
                  <label class="text-uppercase" for="{{'longitude' + i}}">
                    Longitude <span *ngIf="locationFormGroup.get('channel').value === 'IN_STORE'">*</span>
                  </label>
                  <input autocomplete="off" class="form-control" id="{{'longitude' + i}}" type="number"
                         formControlName="longitude">
                </div>
              </div>
            </ng-container>
            <ng-container formArrayName="terminals">
              <div class="d-flex align-items-start mb-3 w-100">
                <label class="text-uppercase col-4">Terminals *</label>
                <div class="d-flex flex-column col-8" style="gap: 5px;">
                  <ng-container
                    *ngFor="let terminalsFormGroup of getAddLocationFormTerminals(i).controls; let j = index">
                    <div [formGroup]="terminalsFormGroup">
                      <input autocomplete="off" class="form-control" id="{{'terminal' + j}}" type="text"
                             formControlName="terminal">
                    </div>
                  </ng-container>
                  <div class="d-flex justify-content-center">
                    <p-button label="Add Terminal" icon="pi pi-plus" styleClass="p-button-text"
                              (onClick)="addTerminalToAddLocationForm(i)"></p-button>
                    <p-button [disabled]="getAddLocationFormTerminals(i).length === 1" label="Remove Terminal"
                              icon="pi pi-minus"
                              styleClass="p-button-text p-button-danger"
                              (onClick)="deleteTerminalFromAddLocationForm(i)"></p-button>
                  </div>
                </div>
              </div>
            </ng-container>
          </div>
        </div>
      </ng-container>
    </ng-container>
    <div class="col-12 d-flex justify-content-center" style="gap: 5px;"
         *ngIf="obj?.channel !== MerchantChannel.ONLINE && !hasOnlineLocation">
      <p-button label="Add Entry" icon="pi pi-plus" styleClass="p-button-sm"
                (onClick)="addLocationToAddLocationForm()"></p-button>
      <p-button [disabled]="getAddLocationFormLocations.value.length === 1" label="Remove Entry" icon="pi pi-minus"
                styleClass="p-button-sm p-button-danger"
                (onClick)="deleteLocationFromAddLocationForm(getAddLocationFormLocations.value.length - 1)"></p-button>
    </div>
  </form>
  <ng-template pTemplate="footer">
    <div
      class="d-flex {{this.obj?.channel !== MerchantChannel.ONLINE ? 'justify-content-between' : 'justify-content-end'}}">
      <p-button *ngIf="this.obj?.channel !== MerchantChannel.ONLINE"
                label="{{useAddressOverLatLng ? 'Switch to Latitude/Longitude input' : 'Switch to Address input'}}"
                class="p-fluid" styleClass="p-button-link p-button-sm"
                (onClick)="useAddressOverLatLng = !useAddressOverLatLng"></p-button>
      <p-button label="Save" styleClass="p-button-sm"
                [disabled]="!addLocationForm.valid || !addLocationForm.dirty"
                (onClick)="handleAddLocationFormSubmit()"></p-button>
    </div>
  </ng-template>
</p-dialog>

<p-dialog modal="modal" header="Edit Location" [draggable]="false" [resizable]="false"
          [(visible)]="showEditLocationForm" [dismissableMask]="true"
          [style]="{width: '900px'}" (onHide)="handleLocationModalClose();">
  <form [formGroup]="editLocationForm" class="p-1" (keydown.enter)="$event.preventDefault()" novalidate>
    <div class="d-flex flex-column form-group col-12">
      <label class="text-uppercase" for="alias">Alias</label>
      <input autocomplete="off" class="form-control" id="alias" type="text" formControlName="alias">
    </div>
    <div class="d-flex flex-column form-group col-12" *ngIf="editLocationForm.get('channel').value === 'ONLINE'">
      <label class="text-uppercase" for="endpoint">Endpoint</label>
      <input disabled autocomplete="off" class="form-control" id="endpoint" type="text" formControlName="endpoint">
    </div>
    <ng-container *ngIf="editLocationForm.get('channel').value === 'IN_STORE'">
      <div *ngIf="useAddressOverLatLng" class="d-flex flex-column form-group col-12">
        <label class="text-uppercase" for="address">
          Address <span *ngIf="editLocationForm.get('channel').value === 'IN_STORE'">*</span>
        </label>
        <input ngx-google-places-autocomplete class="form-control" id="address"
               [options]="googlePlaceApiOptions" #placesRef="ngx-places" formControlName="address"
               (onAddressChange)="handleAddressChange($event)"/>
      </div>
      <div *ngIf="!useAddressOverLatLng" class="d-flex">
        <div class="form-group col-6">
          <label class="text-uppercase" for="latitude">
            Latitude <span *ngIf="editLocationForm.get('channel').value === 'IN_STORE'">*</span>
          </label>
          <input autocomplete="off" class="form-control" id="latitude" type="number" formControlName="latitude">
        </div>
        <div class="form-group col-6">
          <label class="text-uppercase" for="longitude">
            Longitude <span *ngIf="editLocationForm.get('channel').value === 'IN_STORE'">*</span>
          </label>
          <input autocomplete="off" class="form-control" id="longitude" type="number" formControlName="longitude">
        </div>
      </div>
    </ng-container>
  </form>
  <ng-template pTemplate="footer">
    <div class="d-flex justify-content-between">
      <p-button *ngIf="editLocationForm.get('channel').value === 'IN_STORE'"
                label="{{useAddressOverLatLng ? 'Switch to Latitude/Longitude input' : 'Switch to Address input'}}"
                class="p-fluid" styleClass="p-button-link p-button-sm"
                (onClick)="useAddressOverLatLng = !useAddressOverLatLng"></p-button>
      <div *ngIf="editLocationForm.get('channel').value !== 'IN_STORE'"></div>
      <p-button label="Save" styleClass="p-button-sm"
                [disabled]="!editLocationForm.valid || !editLocationForm.dirty"
                (onClick)="handleEditLocationSubmit()"></p-button>
    </div>
  </ng-template>
</p-dialog>

<p-dialog modal="modal" header="Add Terminal" [draggable]="false" [resizable]="false" [(visible)]="showAddTerminalForm"
          [dismissableMask]="true"
          [style]="{width: '900px'}" (onHide)="handleAddTerminalModalClose();">
  <form [formGroup]="addTerminalForm" class="p-1" (keydown.enter)="$event.preventDefault()" novalidate>
    <ng-container formArrayName="terminals">
      <div class="d-flex align-items-start mb-3 w-100">
        <label class="text-uppercase col-4">Terminals *</label>
        <div class="d-flex flex-column col-8" style="gap: 5px;">
          <ng-container *ngFor="let terminalsFormGroup of getAddTerminalFormTerminals.controls; let j = index">
            <div [formGroup]="terminalsFormGroup">
              <input autocomplete="off" class="form-control" id="{{'terminal' + j}}" type="text"
                     formControlName="terminal">
            </div>
          </ng-container>
          <div class="d-flex justify-content-center">
            <p-button label="Add Terminal" icon="pi pi-plus" styleClass="p-button-text"
                      (onClick)="addTerminalToAddTerminalForm()"></p-button>
            <p-button [disabled]="getAddTerminalFormTerminals.length === 1" label="Remove Terminal" icon="pi pi-minus"
                      styleClass="p-button-text p-button-danger"
                      (onClick)="deleteTerminalFromAddTerminalForm()"></p-button>
          </div>
        </div>
      </div>
    </ng-container>
  </form>
  <ng-template pTemplate="footer">
    <p-button label="Save" styleClass="p-button-sm"
              [disabled]="!addTerminalForm.valid || !addTerminalForm.dirty"
              (onClick)="handleAddTerminalFormSubmit()"></p-button>
  </ng-template>
</p-dialog>

<p-dialog modal="modal" header="Create Closed Loop Program" [draggable]="false" [resizable]="false"
          [(visible)]="showNewClosedLoopProgramForm" [dismissableMask]="true" [style]="{width: '800px'}">
  <form [formGroup]="newClosedLoopProgramForm" class="p-1" (ngSubmit)="handleNewClosedLoopProgramSubmit()"
        (keydown.enter)="$event.preventDefault()" novalidate>
    <div class="row">
      <div class="form-group col-12">
        <label class="w-100">
          <span class="text-uppercase">Title *</span>
          <input type="text" class="form-control" formControlName="title">
        </label>
      </div>
      <div class="form-group col-12">
        <label class="w-100">
          <span class="text-uppercase">Description</span>
          <p-editor formControlName="description">
            <ng-template pTemplate="header">
              <span class="ql-formats">
                <button type="button" class="ql-bold" aria-label="Bold"></button>
                <button type="button" class="ql-italic" aria-label="Italic"></button>
                <button type="button" class="ql-underline" aria-label="Underline"></button>
              </span>
            </ng-template>
          </p-editor>
        </label>
      </div>
      <div class="form-group col-12">
        <label class="w-100">
          <span class="text-uppercase">Image</span>
          <input type="text" class="form-control" formControlName="image">
        </label>
      </div>
      <div class="form-group col-12">
        <label class="w-100">
          <span class="text-uppercase">Preview Text</span>
          <input type="text" class="form-control" formControlName="previewText">
        </label>
      </div>
      <div class="form-group col-12">
        <label class="w-100">
          <span class="text-uppercase">Mids</span>
          <p-multiSelect appendTo="body" class="text-capitalize p-fluid" styleClass="form-control p-0"
                         [options]="closedLoopMidOptions"
                         formControlName="mids" optionLabel="name" optionValue="uuid"></p-multiSelect>
        </label>
      </div>
    </div>
    <div class="row">
      <div class="text-left col">
        <hr>
        <button type="submit" class="btn btn-primary btn-custom"
                [disabled]="!newClosedLoopProgramForm.valid || !newClosedLoopProgramForm.dirty" name="saveButton">
          <i class="fa fa-spinner fa-spin" *ngIf="newClosedLoopProgramLoading"></i>
          <span *ngIf="!newClosedLoopProgramLoading">Save</span>
        </button>
      </div>
    </div>
  </form>
</p-dialog>

<p-dialog modal="modal" header="Edit Closed Loop Program" [draggable]="false" [resizable]="false"
          [(visible)]="showEditClosedLoopProgramForm" [dismissableMask]="true" [style]="{width: '800px'}">
  <form [formGroup]="editClosedLoopProgramForm" class="p-1" (ngSubmit)="handleEditClosedLoopProgramSubmit()"
        (keydown.enter)="$event.preventDefault()" novalidate>
    <div class="row">
      <div class="form-group col-12">
        <label class="w-100">
          <span class="text-uppercase">Title *</span>
          <input type="text" class="form-control" formControlName="title">
        </label>
      </div>
      <div class="form-group col-12">
        <label class="w-100">
          <span class="text-uppercase">Description</span>
          <p-editor formControlName="description">
            <ng-template pTemplate="header">
              <span class="ql-formats">
                <button type="button" class="ql-bold" aria-label="Bold"></button>
                <button type="button" class="ql-italic" aria-label="Italic"></button>
                <button type="button" class="ql-underline" aria-label="Underline"></button>
              </span>
            </ng-template>
          </p-editor>
        </label>
      </div>
      <div class="form-group col-12">
        <label class="w-100">
          <span class="text-uppercase">Image</span>
          <input type="text" class="form-control" formControlName="image">
        </label>
      </div>
      <div class="form-group col-12">
        <label class="w-100">
          <span class="text-uppercase">Preview Text</span>
          <input type="text" class="form-control" formControlName="previewText">
        </label>
      </div>
      <div class="form-group col-12">
        <label class="w-100">
          <span class="text-uppercase">Mids *</span>
          <p-multiSelect appendTo="body" class="text-capitalize p-fluid" styleClass="form-control p-0"
                         [options]="closedLoopMidOptions"
                         formControlName="mids" optionLabel="name" optionValue="uuid"></p-multiSelect>
        </label>
      </div>
    </div>
    <div class="row">
      <div class="text-left col">
        <hr>
        <button type="submit" class="btn btn-primary btn-custom"
                [disabled]="!editClosedLoopProgramForm.valid || !editClosedLoopProgramForm.dirty" name="saveButton">
          <i class="fa fa-spinner fa-spin" *ngIf="editClosedLoopProgramLoading"></i>
          <span *ngIf="!editClosedLoopProgramLoading">Save</span>
        </button>
      </div>
    </div>
  </form>
</p-dialog>
