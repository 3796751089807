import {AfterViewInit, Component, OnInit, ViewChild} from '@angular/core';
import {environment} from 'environments/environment';
import {AuthService} from '../../services/auth.service';
import {ConsumersApiService} from '../../services/consumers-api.service';
import {Router} from '@angular/router';
import {MenuItem} from 'primeng/api';
import {Menubar} from 'primeng/menubar';
import {appRoutes} from '../../app-routing.module';

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.css']
})
export class NavbarComponent implements OnInit, AfterViewInit {
  permissions = {
    admin: ['consumers', 'enterprise', 'affiliates', 'wallet', 'payments', 'billing', 'hapi', 'sherlock', 'offers', 'feed', 'deployments'],
    support: ['consumers', 'enterprise', 'affiliates', 'wallet', 'payments', 'billing', 'hapi', 'sherlock', 'offers', 'feed', 'deployments'],
    accounting: ['enterprise', 'payments', 'hapi', 'sherlock', 'offers', 'feed', 'deployments', 'billing'],
    dev: ['consumers', 'enterprise', 'wallet', 'payments', 'billing', 'hapi', 'sherlock', 'offers', 'feed', 'deployments']
  }

  items: MenuItem[];

  @ViewChild('navbar')
  menuBar: Menubar;

  environment: any;
  user: any;
  userLoading = false;

  constructor(public authService: AuthService,
              private consumersService: ConsumersApiService,
              private router: Router) {
    this.environment = environment;
  }

  ngAfterViewInit() {
    this.authService.getOvRoles().subscribe(roles => {
      this.items = this.defaultItems().map(menuItem => {
        const disable = this.permissions[roles[0]].indexOf(menuItem.label.toLowerCase()) === -1;
        menuItem.disabled = disable;
        if (!disable) {
          menuItem.items?.map(item => {
            const routeIndex = appRoutes.findIndex(route => item.routerLink[0] === `/${route.path}`)
            if (routeIndex !== -1) {
              const roleIndex = appRoutes[routeIndex]?.data?.roles.findIndex(role => role === roles[0]);
              item.disabled = roleIndex === -1;
            }
            return item;
          })
        }
        return menuItem;
      });
    });
  }

  ngOnInit() {
    // Safari doesn't let the menu change - default to disabled false so that safari users aren't blocked
    this.items = this.defaultItems();
  }

  defaultItems(): MenuItem[] {
    const consumersTab = {
      label: 'Consumers',
      disabled: false,
      items: [
        {
          label:'Search',
          icon:'pi pi-fw pi-user',
          routerLink: ['/users']
        },
        {
          label:'Leads',
          icon:'pi pi-fw pi-user',
          routerLink: ['/leads']
        },
      ]
    };
    const enterpriseTab = {
      label: 'Enterprise',
      id: 'concierge',
      disabled: false,
      items: [
        {
          label:'Organizations',
          icon:'pi pi-fw pi-user',
          routerLink: ['/enterprise/organizations']
        },
        {
          label:'New Organization',
          icon:'pi pi-fw pi-user-plus',
          routerLink: ['/enterprise/onboarding']
        },
      ]
    };
    const affiliatesTab = {
      label: 'Affiliates',
      // icon:'pi pi-fw pi-bars',
      disabled: false,
      routerLink: ['/affiliates'],
    };
    const walletTab = {
      label: 'Wallet',
      // icon:'pi pi-fw pi-wallet',
      disabled: false,
      items: [
        {
          label:'Firmware',
          icon:'pi pi-fw pi-save',
          routerLink: ['/wallet/versions']
        },
        {
          label:'Bins',
          icon:'pi pi-fw pi-wallet',
          routerLink: ['/wallet/bins']
        },
        {
          label:'Locked Accounts',
          icon:'pi pi-fw pi-lock',
          routerLink: ['/wallet/locked']
        },
        {
          label:'Valet Whitelist',
          icon:'pi pi-fw pi-list',
          routerLink: ['/wallet/valet/whitelist']
        },
        {
          label:'Valet Blacklist',
          icon:'pi pi-fw pi-list',
          routerLink: ['/wallet/valet/blacklist']
        },
        {
          label:'Mobile Blacklist',
          icon:'pi pi-fw pi-list',
          routerLink: ['/wallet/mobile/blacklist']
        }
      ]
    };
    const paymentsTab = {
      label: 'Payments',
      // icon:'pi pi-fw pi-dollar',
      disabled: false,
      items: [
        {
          label:'Transactions',
          icon:'pi pi-fw pi-wifi',
          routerLink: ['/payments/transactions']
        },
        {
          label:'Merchants',
          icon:'pi pi-fw pi-shopping-cart',
          routerLink: ['/payments/merchants']
        },
        {
          label:'Instruments Blacklist',
          icon:'pi pi-fw pi-list',
          routerLink: ['/payments/instruments/blacklist']
        },
      ]
    };
    const billingTab = {
      label: 'Billing',
      // icon:'pi pi-fw pi-inbox',
      disabled: false,
      items: [
        {
          label:'BSPs',
          icon:'pi pi-fw pi-home',
          routerLink: ['/billing/bsps']
        },
        {
          label:'Billers',
          icon:'pi pi-fw pi-envelope',
          routerLink: ['/billing/billers']
        },
        {
          label:'Merchant Billing',
          icon:'pi pi-fw pi-list',
          routerLink: ['/billing/billinghistory']
        },
      ]
    };
    const hapiTab = {
      label: 'HAPI',
      // icon:'pi pi-fw pi-eye',
      disabled: false,
      items: [
        {
          label:'Transactions',
          icon:'pi pi-fw pi-wifi',
          routerLink: ['/transactions']
        },
        {
          label:'ClosedLoop Transactions',
          icon:'pi pi-fw pi-wifi',
          routerLink: ['/hapi/closedlooptransactions']
        },
        {
          label:'Created Cards',
          icon:'pi pi-fw pi-wallet',
          routerLink: ['/hapi/cards/created']
        },
        {
          label:'Open Cards',
          icon:'pi pi-fw pi-wallet',
          routerLink: ['/hapi/cards/open']
        },
      ]
    };
    const sherlockTab = {
      label: 'Sherlock',
      // icon:'pi pi-fw pi-eye',
      disabled: false,
      items: [
        {
          label:'KYC',
          icon:'pi pi-fw pi-eye',
          routerLink: ['/sherlock/kyc/list']
        },
      ]
    };
    const offersTab = {
      label: 'Offers',
      // icon:'pi pi-fw pi-tag',
      disabled: false,
      items: [
        {
          label:'Rewards Campaigns',
          icon:'pi pi-fw pi-dollar',
          routerLink: ['/offers/rewardcampaigns']
        },
        {
          label:'Rewards Programs',
          icon:'pi pi-fw pi-wallet',
          routerLink: ['/offers/rewardprograms']
        },
        {
          label:'Closed Loop Programs',
          icon:'pi pi-fw pi-wallet',
          routerLink: ['/offers/closedloopprograms']
        },
        {
          label:'Activities',
          icon:'pi pi-fw pi-send',
          routerLink: ['/offers/activities']
        },
        {
          label:'Unspent Balances',
          icon:'pi pi-fw pi-dollar',
          routerLink: ['/offers/unspentbalances']
        },
        {
          label:'Users',
          icon:'pi pi-fw pi-user',
          routerLink: ['/offers/users']
        },
        {
          label:'Test Accounts',
          icon:'pi pi-fw pi-list',
          routerLink: ['/offers/testaccounts']
        },
        {
          label:'Brands',
          icon:'pi pi-fw pi-shopping-cart',
          routerLink: ['/offers/brands']
        },
        {
          label:'Merchants',
          icon:'pi pi-fw pi-shopping-cart',
          routerLink: ['/offers/merchants']
        },
        {
          label:'Campaigns',
          icon:'pi pi-fw pi-dollar',
          routerLink: ['/offers/campaigns']
        },
      ]
    };
    const feed = {
      label: 'Feed',
      icon:'pi pi-fw pi-bars',
      disabled: false,
      routerLink: ['/feed'],
    };
    const deployments = {
      label: 'Deployments',
      icon:'pi pi-fw pi-cloud',
      disabled: false,
      routerLink: ['/deployments'],
    };
    return [consumersTab, enterpriseTab, affiliatesTab, walletTab, paymentsTab, billingTab, hapiTab, sherlockTab, offersTab, feed, deployments];
  }

  signOut() {
    this.authService.logout();
  }

  goToUser(email) {
    this.userLoading = true;
    this.consumersService.getPersonByEmail(email).subscribe(persons => {
      this.userLoading = false;
      if (persons && persons.length > 0) {
        this.router.navigateByUrl(`user/${persons[0].id}`);
      }
    }, error1 => {
      this.userLoading = false;
    });
  }

}
