// Build information, automatically generated by `ng-info`
const build = {
    version: "2.23.0",
    timestamp: "Wed Nov 27 2024 17:31:45 GMT+0000 (Coordinated Universal Time)",
    message: null,
    git: {
        user: null,
        branch: "develop",
        hash: "abae50",
        fullHash: "abae50ee61ae1b6a48ba9e9880819a3ad45c645b"
    }
};

export default build;