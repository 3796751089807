import {Injectable} from '@angular/core';
import {BaseService} from '../base.service';
import {HttpClient, HttpParams} from '@angular/common/http';
import {Observable} from 'rxjs';
import {environment} from '../../../environments/environment';
import {catchError, map} from 'rxjs/operators';
import {OffersUser} from '../../domain/offers/user';
import {PageRequest, PageResponse} from '../../domain/common/paging';
import {FilterField} from '../../domain/common/search/filter-field';
import {BuildInfo} from '../../domain/common/buildinfo';
import {UnspentBalance} from '../../domain/offers/unspentBalance';
import {MerchantType, OffersMerchant} from '../../domain/offers/merchant/offers-merchant';
import {Campaign} from '../../domain/offers/campaign';
import {TimeUnit} from '../../domain/forms/timeunit';
import {RewardProgram} from '../../domain/offers/reward-program/reward-program';
import {RewardCampaign} from '../../domain/offers/reward-campaign/reward-campaign';
import {PresignedUrl} from '../../domain/offers/presigned-url';
import {RewardCampaignUpdateRequest} from '../../domain/offers/reward-campaign/reward-campaign-update-request';
import {RewardProgramUpdateRequest} from '../../domain/offers/reward-program/reward-program-update-request';
import {RewardProgramFundsRequest} from '../../domain/offers/reward-program/reward-program-funds-request';
import {RewardProgramFundLogResponse} from '../../domain/offers/reward-program/reward-program-funds-log-response';
import {RewardActivityResponse} from '../../domain/offers/reward-activity-response';
import {RewardActivityTotals} from '../../domain/offers/reward-activity-type-total';
import {RewardProgramCreateRequest} from '../../domain/offers/reward-program/reward-program-create-request';
import {QualifyingRewardTransaction, RewardProgramTransaction} from '../../domain/offers/reward-program/reward-program-transaction';
import {Transaction} from '../../domain/offers/transaction';
import {RewardProgramSubscriber} from '../../domain/offers/reward-program/reward-program-subscriber';
import {OffersMerchantCreateRequest} from '../../domain/offers/merchant/offers-merchant-create-request';
import {OffersMerchantUpdateRequest} from '../../domain/offers/merchant/offers-merchant-update-request';
import {RewardCampaignCreateRequest} from '../../domain/offers/reward-campaign/reward-campaign-create-request';
import {BrandLocation} from '../../domain/offers/merchant/brand-location';
import {ClosedLoopProgram} from '../../domain/offers/programs/closed-loop-program';
import {ClosedLoopProgramRequest} from '../../domain/offers/programs/closed-loop-program-request';
import {ClosedLoopProgramUpdateRequest} from '../../domain/offers/programs/closed-loop-program-update-request';

@Injectable()
export class OffersService  extends BaseService {

  constructor(private http: HttpClient) {
    super();
  }

  getBuildInfo() {
    return this.http.get<BuildInfo>(`${environment.offersUrl}/build`);
  }

  getUnspentBalances(pageRequest: PageRequest): Observable<PageResponse<UnspentBalance>> {
    const searchParams: HttpParams = new HttpParams()
      .append('page', `${pageRequest.page}`)
      .append('size', `${pageRequest.size}`);
    const url = `${environment.offersUrl}/admin/fund`;
    return this.http.get<PageResponse<UnspentBalance>>(url, {params: searchParams})
      .pipe(
        catchError(
          this.handleError
        )
      );
  }

  getLoans(userUuid: string): Observable<any> {
    return this.http.get<any>(environment.offersUrl + '/loan')
      .pipe(
        catchError(
          this.handleError
        )
      );
  }

  getOffers(userUuid: string): Observable<any> {
    return this.http.get<any>(environment.offersUrl + '/offer')
      .pipe(
        catchError(
          this.handleError
        )
      );
  }

  uploadImageToPresignedUrl(url: string, image: any): Observable<any> {
    const formData: FormData = new FormData();
    formData.append('uploadFile', image, image.name);
    return this.http.put(url, image)
  }

  /*
  * User
  * */

  getOffersUserPage(pageRequest: PageRequest, filters: FilterField[] = []): Observable<PageResponse<OffersUser>> {
    let searchParams: HttpParams = new HttpParams()
      .append('page', `${pageRequest.page}`)
      .append('size', `${pageRequest.size}`);

    filters.forEach(filter => {
      if (filter.value != null) {
        searchParams = searchParams.append(filter.parameter, filter.value);
      }
    });

    const url = `${environment.offersUrl}/user/search`;
    return this.http.get<PageResponse<OffersUser>>(url, {params: searchParams})
      .pipe(
        catchError(
          this.handleError
        )
      );
  }

  getOffersUser(userUuid: string): Observable<OffersUser> {
    return this.http.get<OffersUser>(`${environment.offersUrl}/admin/user/${userUuid}`)
      .pipe(
        catchError(
          this.handleError
        )
      );
  }

  createOffersUser(userUuid: string): Observable<OffersUser> {
    const body = {};
    return this.http.post<OffersUser>(`${environment.offersUrl}/admin/user/${userUuid}`, body)
      .pipe(
        catchError(
          this.handleError
        )
      );
  }

  setHold(uuid: string, hold: boolean, holdReason: string = null) {
    const body = {hold, holdReason: (hold ? holdReason : null)};
    return this.http.put<OffersUser>(`${environment.offersUrl}/admin/user/${uuid}`, body);
  }

  setCreditLimit(uuid: string, creditLimit: boolean) {
    const body = {creditLimit};
    return this.http.put<OffersUser>(`${environment.offersUrl}/admin/user/${uuid}`, body);
  }

  deleteOffersUser(uuid: string): Observable<any> {
    return this.http.delete(`${environment.offersUrl}/admin/user/${uuid}`)
      .pipe(
        catchError(
          this.handleError
        )
      );
  }

  addTestAccount(email: string): Observable<any> {
    const body = {email};
    return this.http.post(`${environment.offersUrl}/admin/user/testaccount`, body)
      .pipe(
        catchError(
          this.handleError
        )
      );
  }

  getAllTestAccounts(pageRequest: PageRequest): Observable<PageResponse<OffersUser>> {
    const searchParams: HttpParams = new HttpParams()
      .append('page', `${pageRequest.page}`)
      .append('size', `${pageRequest.size}`);

    return this.http.get<PageResponse<OffersUser>>(`${environment.offersUrl}/admin/user/testaccounts`, {params: searchParams})
      .pipe(
        catchError(
          this.handleError
        )
      );
  }

  removeTestAccount(userUuid: string): Observable<any> {
    return this.http.delete<PageResponse<any>>(`${environment.offersUrl}/admin/user/${userUuid}/testaccount`)
      .pipe(
        catchError(
          this.handleError
        )
      );
  }

  /*
  * Campaigns
  * */

  private campaignMapper(campaign: Campaign) {
    console.log(campaign);
    campaign.percentBased = (campaign.percentOff?.length > 0);
    campaign.discount = (campaign.percentBased) ? campaign.percentOff : campaign.dollarOff;
    campaign.durationUnit = TimeUnit.hour;
    campaign.durationTime = campaign.durationHours;
    campaign.collectionLimited = !(campaign.collectLimit === 0);
    campaign.feedCollectionLimited = !(campaign.feedCollectLimit === 0);
    campaign.collectLimit = (campaign.collectLimit === -1) ? 0 : campaign.collectLimit;
    campaign.feedCollectLimit = (campaign.feedCollectLimit === -1) ? 0 : campaign.feedCollectLimit;
    campaign.isTimeBasedOffer = (campaign.weekdays && campaign.weekdays.length > 0) ||
      (campaign.monthdays && campaign.monthdays.length > 0) ||
      (campaign.months && campaign.months.length > 0) ||
      (campaign.startTime && campaign.startTime.length > 0) ||
      (campaign.endTime && campaign.endTime.length > 0);

    campaign.monthdaysDates = campaign.monthdays && campaign.monthdays.length > 0 ? campaign.monthdays.map(it => {
      const date = new Date();
      date.setDate(+it);
      date.setFullYear(2017);
      date.setMonth(0);
      date.setHours(0);
      date.setMinutes(0);
      date.setSeconds(0);
      date.setMilliseconds(0);
      return date;
    }) : null;

    campaign.startTimeMapped = (campaign.startTime && campaign.startTime.length > 0) ? {
      hour: (campaign.startTime.substring(0, campaign.startTime.indexOf(':'))),
      minute: (campaign.startTime.substring(campaign.startTime.indexOf(':') + 1, campaign.startTime.lastIndexOf(':')))
    } : null;

    campaign.endTimeMapped = (campaign.endTime && campaign.endTime.length > 0) ? {
      hour: (campaign.endTime.substring(0, campaign.endTime.indexOf(':'))),
      minute: (campaign.endTime.substring(campaign.endTime.indexOf(':') + 1, campaign.endTime.lastIndexOf(':')))
    } : null;

    return campaign;
  }

  save(campaign: Campaign) {
    const body = {
      merchantUuid: campaign.merchant.uuid,
      description: campaign.description,
      dollarOff: !campaign.percentBased ? campaign.discount : '',
      percentOff: (campaign.percentBased) ? campaign.discount : '',
      durationHours: TimeUnit.toHours(campaign.durationTime, campaign.durationUnit),
      saveUpTo: campaign.percentBased ? campaign.saveUpTo : campaign.discount,
      whenSpend: campaign.whenSpend,
      feedCollectLimit: !campaign.feedCollectionLimited ? 0 : campaign.feedCollectLimit === 0 ? -1 : campaign.feedCollectLimit,
      collectLimit: !campaign.collectionLimited ? 0 : campaign.collectLimit === 0 ? -1 : campaign.collectLimit,
      recollect: campaign.recollect,
      kycRequired: campaign.kycRequired,
      onKyc: campaign.onKyc,
      purchases: campaign.purchases,
      purchasesAtLeast: campaign.purchasesAtLeast,
      visits: campaign.visits,
      totalSpend: campaign.totalSpend,
      loyaltyPercentOff: campaign.loyaltyPercentOff,
      loyaltyDollarOff: campaign.loyaltyDollarOff,
      maxValue: campaign.maxValue,
      timeZone: campaign.timeZone,
      weekdays: campaign.isTimeBasedOffer && campaign.weekdays ? campaign.weekdays : [],
      monthdays: campaign.monthdaysDates ? campaign.monthdaysDates.map(it => it.getDate()) : [],
      months: campaign.isTimeBasedOffer && campaign.months ? campaign.months : [],
      startTime: campaign.isTimeBasedOffer && campaign.startTimeMapped && campaign.startTimeMapped.hour ? campaign.startTimeMapped.hour + ':' + (campaign.startTimeMapped.minute ? campaign.startTimeMapped.minute : '00') + ':00' : '',
      endTime: campaign.isTimeBasedOffer && campaign.endTimeMapped  && campaign.endTimeMapped.hour ? campaign.endTimeMapped.hour + ':' + (campaign.endTimeMapped.minute ? campaign.endTimeMapped.minute : '00') + ':00' : '',
      offerUrl: campaign.offerUrl,
      // 'credit':  isAdmin ? campaign.credit : null,
      // 'apr':  isAdmin ? campaign.apr : null,
    };

    if (campaign.id && campaign.id !== 0) {
      return this.http.put<Campaign>(environment.offersUrl + '/admin/campaign/' + campaign.id, body);
      // .map(updatedCampaign => this.updateCache(this.campaignMapper(updatedCampaign), this.campaignCache));
    } else {
      return this.http.post<Campaign>(environment.offersUrl + '/admin/campaign', body);
      // .map(updatedCampaign => {
      // this.updateCache(this.campaignMapper(updatedCampaign), this.campaignCache);
      // this.updateMerchant(updatedCampaign, this.merchantService.merchantCache);
      // return updatedCampaign;
      // });
    }
  }

  getCampaigns(merchantUuid: string = null) {
    return this.http.get<Campaign[]>(environment.offersUrl + '/admin/campaign' + (merchantUuid ? '?merchant=' + merchantUuid : '' )).pipe(
      map(campaigns => {
        campaigns.forEach(it => this.campaignMapper(it));
        return campaigns;
      })
    );
  }

  /*
  * Merchants
  * */

  getMerchant(uuid: string): Observable<OffersMerchant> {
    return this.http.get<OffersMerchant>(environment.offersUrl + '/admin/merchant/' + uuid );
    //   .pipe(
    //   map(merchant => {
    //     // merchants.forEach(it => {
    //     //   this.merchantMapper(it);
    //     // });
    //     return this.merchantMapper(merchant);
    //   })
    // );
  }

  deleteMerchant(uuid: string): Observable<any> {
    return this.http.delete<any>(`${environment.offersUrl}/admin/merchant/${uuid}/locations`);
  }

  // private merchantMapper(merchant: OffersMerchant) {
  //   if (merchant.merchantIdentifier) {
  //     const paddedId = merchant.merchantIdentifier.padEnd(40, ' ').toUpperCase();
  //     merchant.identifier = paddedId.substring(19, 38).trim();
  //     merchant.city = paddedId.substring(3, 19).replace(/\./g, ' ').trim();
  //     merchant.state = paddedId.substring(0, 3).replace(/\./g, ' ').trim();
  //   }
  //   // if (!merchant.keywords || merchant.keywords.length === 0) { merchant.keywordsArray = []; } else {
  //   //   merchant.keywordsArray = merchant.keywords.split(',');
  //   // }
  //   return merchant;
  // }

  private formatMerchantId(city: string, state: string, identifier: string) {
    if (!city && !state && !identifier) {
      return null;
    }
    const cityFormatted = (!city) || (city.length === 0) ? ('').padEnd(16, '.') : city.padEnd(16, '.');
    const stateFormatted = (((!state) || state.toUpperCase() === 'ALL') ? '...' : state.padEnd(3, ' '));
    return (stateFormatted + cityFormatted + identifier.padEnd(19, ' ')).toUpperCase();
  }

  createBrand(request: OffersMerchantCreateRequest): Observable<OffersMerchant> {
    request['type'] = MerchantType.BRAND;

    return this.http.post<OffersMerchant>(`${environment.offersUrl}/admin/merchant`, request);
    //   .pipe(
    //   map( merchant => {
    //       return this.merchantMapper(merchant);
    //     }
    //   )
    // );
  }

  createMid(request: OffersMerchantCreateRequest): Observable<OffersMerchant> {
    request['type'] = MerchantType.MID;

    return this.http.post<OffersMerchant>(`${environment.offersUrl}/admin/merchant`, request);
    //   .pipe(
    //   map(merchant => {
    //       return this.merchantMapper(merchant);
    //     }
    //   )
    // );
  }

  getMerchants(pageRequest: PageRequest = null, filters: {} = null, unpaged: boolean = false): Observable<PageResponse<OffersMerchant>> {
    let searchParams: HttpParams = new HttpParams()
      .append('sort', `updated,created,desc`);

    if (unpaged) {
      searchParams = searchParams.append('unpaged', `${unpaged}`);
    } else {
      searchParams = searchParams.append('page', `${pageRequest.page}`);
      searchParams = searchParams.append('size', `${pageRequest.size}`);
    }

    Object.keys(filters).forEach(key => {
      if (filters[key]) {
        if (key === 'uuid') {
          searchParams = searchParams.append('brandId', filters[key]);
        } else {
          searchParams = searchParams.append(key, filters[key]);
        }
      }
    });

    return this.http.get<PageResponse<OffersMerchant>>(environment.offersUrl + `/admin/merchant`, {params: searchParams});
    //   .pipe(
    //   map(result => {
    //     result.content.forEach(it => {
    //       this.merchantMapper(it);
    //     });
    //     return result;
    //   })
    // );
  }

  updateMerchant(uuid: string, request: OffersMerchantUpdateRequest): Observable<OffersMerchant> {
    return this.http.put<OffersMerchant>(`${environment.offersUrl}/admin/merchant/${uuid}`, request);
    //   .pipe(
    //   map( merchant => {
    //       return this.merchantMapper(merchant);
    //     }
    //   )
    // );
  }

  // createDollarOffCampaign(merchantUuid: string, description: string, owner: string, identifier: string, city: string, state: string, giftOption: boolean, verified: boolean): Observable<OffersMerchant> {
  //   const body = {
  //     description,
  //     owner,
  //     name,
  //     giftOption,
  //     verified,
  //   };
  //   return this.http.post<OffersMerchant>(`${environment.offersUrl}/admin/merchant`, body).pipe(
  //     map( merchant => {
  //         return this.merchantMapper(merchant);
  //       }
  //     )
  //   );
  // }

  getBrandLocations(brandId: string, pageRequest: PageRequest): Observable<PageResponse<BrandLocation>> {
    const searchParams: HttpParams = new HttpParams()
      .append('brandId', `${brandId}`)
      .append('page', `${pageRequest.page}`)
      .append('size', `${pageRequest.size}`);

    return this.http.get<PageResponse<BrandLocation>>(environment.offersUrl + `/admin/merchant/locations`, {params: searchParams});
  }

  /*
  * Reward Campaigns
  * */

  createRewardCampaign(request: RewardCampaignCreateRequest): Observable<RewardCampaign> {
    return this.http.post<RewardCampaign>(`${environment.offersUrl}/admin/reward/campaign`, request)
      .pipe(
        catchError(
          this.handleError
        )
      );
  }

  getRewardCampaigns(pageRequest: PageRequest, filterParams: FilterField[]): Observable<PageResponse<RewardCampaign>> {
    let searchParams: HttpParams = new HttpParams()
      .append('page', `${pageRequest.page}`)
      .append('size', `${pageRequest.size}`);

    filterParams.forEach(filter => {
      if (filter.value) {
        searchParams = searchParams.append(filter.parameter, filter.value);
      }
    });

    return this.http.get<PageResponse<RewardCampaign>>(`${environment.offersUrl}/admin/reward/campaign`, {params: searchParams})
      .pipe(
        catchError(
          this.handleError
        )
      );
  }

  getRewardCampaign(uuid: string) {
    return this.http.get<RewardCampaign>(`${environment.offersUrl}/admin/reward/campaign/${uuid}`)
      .pipe(
        catchError(
          this.handleError
        )
      );
  }

  deleteRewardCampaign(uuid: string): Observable<any> {
    return this.http.delete<any>(`${environment.offersUrl}/admin/reward/campaign/${uuid}`);
  }

  updateRewardCampaign(uuid: string, details: RewardCampaignUpdateRequest): Observable<RewardCampaign> {
    return this.http.put<RewardCampaign>(`${environment.offersUrl}/admin/reward/campaign/${uuid}`, details);
  }

  getPresignedImageUrl(): Observable<PresignedUrl> {
    return this.http.post<PresignedUrl>(`${environment.offersUrl}/admin/reward/campaign/media`, {mimeType: 'image/png'});
  }

  /*
  * Reward Programs
  * */

  getRewardProgram(uuid: string) {
    return this.http.get<RewardProgram>(environment.offersUrl + `/admin/reward/programs/${uuid}`)
      .pipe(
        map(rewardProgram => {
          rewardProgram.poolBalance = rewardProgram.poolBalance.replace(',', '');
          rewardProgram.unspentRewards = rewardProgram.unspentRewards.replace(',', '');
          return rewardProgram;
        })
      );
  }

  getRewardPrograms(pageRequest: PageRequest = null, filterParams: FilterField[] = [], unpaged = false) {
    let searchParams: HttpParams = new HttpParams()

    if (unpaged) {
      searchParams = searchParams.append('unpaged', 'true');
    } else {
      if (pageRequest) {
        searchParams = searchParams.append('page', `${pageRequest.page}`);
        searchParams = searchParams.append('size', `${pageRequest.size}`);
      }
    }
    filterParams.forEach(filter => {
      if (filter.value) {
        searchParams = searchParams.append(filter.parameter, filter.value);
      }
    });
    return this.http.get<PageResponse<RewardProgram>>(environment.offersUrl + '/admin/reward/programs', {params: searchParams});
  }

  updateRewardProgram(uuid: string, details: RewardProgramUpdateRequest): Observable<RewardProgram> {
    return this.http.put<RewardProgram>(`${environment.offersUrl}/admin/reward/programs/${uuid}`, details);
  }

  createRewardProgram(details: RewardProgramCreateRequest): Observable<RewardProgram> {
    return this.http.post<RewardProgram>(`${environment.offersUrl}/admin/reward/programs`, details);
  }

  // getRewardProgramLog(brandId: string, pageRequest: PageRequest): Observable<PageResponse<RewardProgramLog>> {
  //   let searchParams: HttpParams = new HttpParams()
  //     .append('page', `${pageRequest.page}`)
  //     .append('size', `${pageRequest.size}`)
  //     .append('brandId', brandId);
  //   return this.http.get<PageResponse<RewardProgramLog>>(environment.offersUrl + `/admin/reward/programs/log`, {params: searchParams});
  // }

  // getRewardProgramBalances(brandId: string) {
  //   return this.http.get<any>(environment.offersUrl + `/admin/reward/programs/balance?brandId=${brandId}`);
  // }

  // getRewardProgramBalance(campaignId: string) {
  //   return this.http.get<any>(environment.offersUrl + `/admin/reward/programs/balance?rewardCampaignId=${campaignId}`);
  //   // .pipe(first(balance => balance.rewardCampaignId === campaignId))
  // }

  getRewardProgramRewardActivity(brandId: string, pageRequest: PageRequest, filterParams: FilterField[], unpaged: boolean = false): Observable<PageResponse<RewardActivityResponse>> {
    let searchParams: HttpParams = new HttpParams()
      .append('brandId', brandId)
      .append('sort', 'transactionDate,desc');

    if (unpaged) {
      searchParams = searchParams.append('unpaged', 'true');
    } else {
      searchParams = searchParams.append('page', `${pageRequest.page}`)
      searchParams = searchParams.append('size', `${pageRequest.size}`)
    }
    filterParams.forEach(filter => {
      if (filter.value) {
        searchParams = searchParams.append(filter.parameter, filter.value);
      }
    });

    return this.http.get<PageResponse<RewardActivityResponse>>(environment.offersUrl + `/admin/activity/reward`, {params: searchParams});
  }

  getRewardProgramActivityTotals(brandId: string, filterParams: FilterField[]): Observable<RewardActivityTotals> {
    let searchParams: HttpParams = new HttpParams()
      .append('brandId', brandId);

    filterParams.forEach(filter => {
      if (filter.value) {
        searchParams = searchParams.append(filter.parameter, filter.value);
      }
    });
    return this.http.get<RewardActivityTotals>(environment.offersUrl + `/admin/activity/reward/totals`, {params: searchParams})
      .pipe(
        map(totals => {
          totals.totals = totals?.totals?.map(total => {
            if (total?.amount < 0) {
              total.amount = total.amount * -1
              return total;
            }
            return total;
          });
          return totals;
        })
      );
  }

  saveRewardProgramFunds(uuid: string, rewardProgramFundsRequest: RewardProgramFundsRequest): Observable<RewardProgramFundLogResponse> {
    return this.http.post<RewardProgramFundLogResponse>(`${environment.offersUrl}/admin/reward/programs/${uuid}/funds`, rewardProgramFundsRequest);
  }

  getRewardProgramFunds(uuid: string, pageRequest: PageRequest, unpaged: boolean = false): Observable<PageResponse<RewardProgramFundLogResponse>> {
    let searchParams: HttpParams = new HttpParams()

    if (unpaged) {
      searchParams = searchParams.append('unpaged', 'true');
    } else {
      searchParams = searchParams.append('page', `${pageRequest.page}`);
      searchParams = searchParams.append('size', `${pageRequest.size}`);
    }
    return this.http.get<PageResponse<RewardProgramFundLogResponse>>(`${environment.offersUrl}/admin/reward/programs/${uuid}/funds`, {params: searchParams});
  }

  deleteRewardProgram(uuid: string): Observable<any> {
    return this.http.delete<any>(`${environment.offersUrl}/admin/reward/programs/${uuid}`);
  }

  getRewardProgramTransactions(uuid: string, pageRequest: PageRequest = null, filterParams: FilterField[] = [], unpaged = false): Observable<PageResponse<RewardProgramTransaction>> {
    let searchParams: HttpParams = new HttpParams();

    if (unpaged) {
      searchParams = searchParams.append('unpaged', 'true');
    } else {
      searchParams = searchParams.append('page', `${pageRequest.page}`)
      searchParams = searchParams.append('size', `${pageRequest.size}`)
    }
    filterParams.forEach(filter => {
      if (filter.value) {
        searchParams = searchParams.append(filter.parameter, filter.value);
      }
    });

    return this.http.get<QualifyingRewardTransaction>(environment.offersUrl + `/admin/reward/programs/${uuid}/transactions`, {params: searchParams})
      .pipe(
        map(qualifyingRewardTransaction => qualifyingRewardTransaction.transactions)
      );
  }

  getRewardProgramSubscribers(uuid: string, pageRequest: PageRequest = null, filterParams: FilterField[] = [], unpaged = false): Observable<PageResponse<RewardProgramSubscriber>> {
    let searchParams: HttpParams = new HttpParams();

    if (unpaged) {
      searchParams = searchParams.append('unpaged', 'true');
    } else {
      searchParams = searchParams.append('page', `${pageRequest.page}`)
      searchParams = searchParams.append('size', `${pageRequest.size}`)
    }
    filterParams.forEach(filter => {
      if (filter.value) {
        searchParams = searchParams.append(filter.parameter, filter.value);
      }
    });

    return this.http.get<PageResponse<RewardProgramSubscriber>>(environment.offersUrl + `/admin/reward/programs/${uuid}/subscribers`, {params: searchParams});
  }

  /*
  * Transactions
  * */

  getTransaction(uuid: string): Observable<Transaction> {
    return this.http.get<Transaction>(`${environment.offersUrl}/admin/transactions/${uuid}`);
  }

  /*
  * Program
  * */

  getClosedLoopPrograms(brandId: string, pageRequest: PageRequest, unpaged = false): Observable<PageResponse<ClosedLoopProgram>> {
    let searchParams: HttpParams = new HttpParams();

    if (brandId) {
      searchParams = searchParams.append('brandId', brandId);
    }

    if (unpaged) {
      searchParams = searchParams.append('unpaged', 'true');
    } else {
      searchParams = searchParams.append('page', `${pageRequest.page}`)
      searchParams = searchParams.append('size', `${pageRequest.size}`)
    }

    return this.http.get<PageResponse<ClosedLoopProgram>>(environment.offersUrl + `/admin/closedloop/programs`, {params: searchParams})
      .pipe(
        catchError(
          this.handleError
        )
      );
  }

  getClosedLoopProgram(uuid: string): Observable<ClosedLoopProgram> {
    return this.http.get<ClosedLoopProgram>(environment.offersUrl + `/admin/closedloop/programs/${uuid}`, )
      .pipe(
        catchError(
          this.handleError
        )
      );
  }

  createClosedLoopProgram(request: ClosedLoopProgramRequest): Observable<ClosedLoopProgram> {
    return this.http.post<ClosedLoopProgram>(environment.offersUrl + `/admin/closedloop/programs/`, request)
      .pipe(
        catchError(
          this.handleError
        )
      );
  }

  updateClosedLoopProgram(uuid: string, request: ClosedLoopProgramUpdateRequest): Observable<ClosedLoopProgram> {
    return this.http.put<ClosedLoopProgram>(environment.offersUrl + `/admin/closedloop/programs/${uuid}`, request)
      .pipe(
        catchError(
          this.handleError
        )
      );
  }

  deleteClosedLoopProgram(uuid: string) {
    return this.http.delete(environment.offersUrl + `/admin/closedloop/programs/${uuid}`)
      .pipe(
        catchError(
          this.handleError
        )
      );
  }
}
